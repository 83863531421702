<template>
  <v-container v-if="isAppAdmin(this.$store.state.user._id)" grid-list-md pa-2>
    <div xs12>
      <v-card outlined>
        <v-card-text class="text-center">
          UTC Time: {{ getLATime() }}<br />
          <EmojiHappyIcon class="hero-icon" style="vertical-align
              : middle;" size="80" :color="themeColor('primary')"></EmojiHappyIcon>
          <span class="primary--text" style="font-size: 6em; vertical-align: middle">{{
            newUsersCount
            }}</span>
          <table width="100%" style="font-size: 1.5em">
            <tr>
              <td width="52%" style="text-align: right">Today:</td>
              <td style="text-align: left">{{ today }}</td>
            </tr>
            <tr>
              <td style="text-align: right">Yesterday:</td>
              <td style="text-align: left">{{ yesterday }}</td>
            </tr>
          </table>
        </v-card-text>
      </v-card>
    </div>
    <v-row>
      <v-col>
        <div xs12 lg6>
          <v-card style="background-color: #a97942">
            <svg id="icon_academy" width="64px" height="64px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
              <path
                d="M3.63,11v1.8h.7V11a1.17,1.17,0,0,0,.54-.31,1.24,1.24,0,0,0,1.79,0,1.24,1.24,0,0,0,.89.38,1.26,1.26,0,0,0,.89-.38,1.24,1.24,0,0,0,.89.38,1.26,1.26,0,0,0,.89-.38,1.24,1.24,0,0,0,.89.38,1.26,1.26,0,0,0,.89-.38,1.26,1.26,0,0,0,.89.38,1.24,1.24,0,0,0,.89-.38,1.26,1.26,0,0,0,.89.38,1.24,1.24,0,0,0,.89-.38,1.26,1.26,0,0,0,.89.38,1.24,1.24,0,0,0,.89-.38,1.24,1.24,0,0,0,1.79,0,1.17,1.17,0,0,0,.54.31v7.68H18.58V12.79H14.32v5.93H7.84v.7H20.37V11a1.22,1.22,0,0,0,.89-1.17V6.25H19.42V7h1.14V9.87a.55.55,0,0,1-1.09,0V8.39h-.69V9.87a.55.55,0,0,1-1.09,0V8.39H17V9.87a.54.54,0,0,1-.54.54.54.54,0,0,1-.54-.54V8.39h-.7V9.87a.54.54,0,1,1-1.08,0V8.39h-.7V9.87a.54.54,0,1,1-1.08,0V8.39h-.7V9.87a.54.54,0,1,1-1.08,0V8.39h-.7V9.87a.54.54,0,0,1-1.08,0V8.39h-.7V9.87a.54.54,0,0,1-.54.54A.54.54,0,0,1,7,9.87V8.39h-.7V9.87a.55.55,0,0,1-1.09,0V8.39H4.53V9.87a.55.55,0,0,1-1.09,0V7H4.58v-.7H2.74V9.87A1.22,1.22,0,0,0,3.63,11ZM15,16.45h.54v-.7H15V13.49h2.86v5.23H15Z" />
              <path d="M8.44,16.94v.7h4.21V12.79h-6v.7H9v3.45Zm1.24-3.45H12v3.45H9.68Z" />
              <path
                d="M6.06,7.84H17.94a.94.94,0,0,0,.94-.94V2.45a1,1,0,0,0-.94-1H6.06a1,1,0,0,0-.94,1V6.9A.94.94,0,0,0,6.06,7.84ZM5.82,2.45a.25.25,0,0,1,.24-.25H17.94a.25.25,0,0,1,.24.25V6.9a.25.25,0,0,1-.24.25H6.06a.25.25,0,0,1-.24-.25Z" />
              <path
                d="M5.52,19.91V19.4C7,19.3,7.9,18.55,7.9,17.29s-2.21-4.08-2.46-4.38a.36.36,0,0,0-.54,0c-.25.3-2.45,3-2.45,4.38s.86,2,2.37,2.11v.51H2.5v.7h19v-.7ZM3.15,17.29c0-.85,1.28-2.66,2-3.6.74.94,2,2.75,2,3.6s-.57,1.34-1.68,1.42v-2h-.7v2C3.71,18.63,3.15,18.15,3.15,17.29Z" />
            </svg>
            <span class="secondary-count">{{ newAcademiesCount }}</span>
            <v-card-text style="font-size: 1.2em">
              <table width="100%" style="margin-left: auto; margin-right: auto">
                <tr>
                  <td width="2%">
                    <BadgeCheckIcon class="hero-icon"></BadgeCheckIcon>
                  </td>
                  <td style="text-align: left">Claimed Academies:</td>
                  <td style="text-align: left">{{ claimedAcademiesCount }}</td>
                </tr>
                <tr>
                  <td>
                    <HandIcon class="hero-icon"></HandIcon>
                  </td>
                  <td style="text-align: left">Managed Academies:</td>
                  <td style="text-align: left">{{ managedAcademiesCount }}</td>
                </tr>
                <tr>
                  <td>
                    <CashIcon class="hero-icon"></CashIcon>
                  </td>
                  <td style="text-align: left">Managed Academies w/ payments:</td>
                  <td style="text-align: left">
                    {{ managedAcademiesWithPaymentsCount }}
                  </td>
                </tr>
              </table>
            </v-card-text>
          </v-card>
        </div>
      </v-col>
      <v-col>
        <div xs12 lg6>
          <v-card style="background-color: #8d70b2" class="secondary-count">
            <svg id="icon_map" width="64px" height="64px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
              <path
                d="M18.09,7.74A6.08,6.08,0,0,0,6,6.84a.32.32,0,0,0-.06.3s0,0,0,0c0,.19,0,.38,0,.57a6.12,6.12,0,0,0,.27,1.77h0a6.1,6.1,0,0,0,4.31,4.1l.55,7.8a1,1,0,0,0,1.92,0l.56-7.8A6.08,6.08,0,0,0,18.09,7.74Zm-7.9,2.44L8.8,9.08l2.79-1L13,8.56v.73l-1.26.4,0,0ZM6.61,7.74c0-.16,0-.3,0-.45l1.41-.42,2.43.86L6.77,9A5,5,0,0,1,6.61,7.74ZM13,5.4l-.07,0,.07,0Zm0,.74V7.81L8.8,6.38l1.39-1.11Zm.7,3.43V5.88h0V5.6L15,6.67V8.76L13.67,9.83Zm2.07-2.82,1.42-.48a5.51,5.51,0,0,1,.23,1.47c0,.07,0,.13,0,.2l-1.63.51ZM12,2.35a5.37,5.37,0,0,1,4.94,3.26l-1.49.5-1.92-1.5h0a.35.35,0,0,0-.17-.06h-.15L11.7,5l-1.48-.46a.34.34,0,0,0-.32.06l-2,1.57-1.17.35A5.39,5.39,0,0,1,12,2.35Zm.8,11.42-.54,7.6a.26.26,0,1,1-.52,0l-.54-7.61a5.48,5.48,0,0,0,.57.05,5.27,5.27,0,0,0,.69,0l.22,0Zm.34-.77h-.05l-.15,0a5.87,5.87,0,0,1-.94.09,6.31,6.31,0,0,1-.76-.07L10.91,13H10.8A5.4,5.4,0,0,1,7,9.63L8,9.3,9.9,10.84a.3.3,0,0,0,.22.08h.1l1.61-.51,1.35.48a.23.23,0,0,0,.11,0h0a.33.33,0,0,0,.21-.07l2-1.58,1.74-.55A5.4,5.4,0,0,1,13.14,13Z" />
            </svg>
            {{ checkinsCount }}
          </v-card>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <div xs12 lg6>
          <v-card style="background-color: #1b75bc" class="secondary-count">
            <MapIcon class="hero-icon" style="vertical-align: middle; margin-bottom: 15px" size="60" color="black">
            </MapIcon>
            {{ countriesCount }}
          </v-card>
        </div>
      </v-col>
      <v-col>
        <div xs12 lg6>
          <v-card style="background-color: white;color:black" class="secondary-count">
            <LocationMarkerIcon style="vertical-align: middle; margin-bottom: 15px" size="60" color="black">
            </LocationMarkerIcon>
            {{ citiesCount }}
          </v-card>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <div xs12 lg6>
          <v-card style="background-color: #ec7063" class="secondary-count">
            <VideoCameraIcon class="hero-icon" style="vertical-align: middle; margin-bottom: 15px" size="60"
              color="black">
            </VideoCameraIcon>
            {{ videosCount }}
          </v-card>
        </div>
      </v-col>
      <v-col>
        <div xs12 lg6>
          <v-card style="background-color: #ffd700" class="secondary-count">
            <AnnotationIcon class="hero-icon" style="vertical-align: middle; margin-bottom: 15px" size="60"
              color="black">
            </AnnotationIcon>
            {{ postsCount }}
          </v-card>
        </div>
      </v-col>
    </v-row>
    <div xs12>
      <v-card>
        <ApexChart v-if="activityLast3MonthsOptions" type="line" height="350" :options="activityLast3MonthsOptions"
          :series="activityLast3MonthsData"></ApexChart>
      </v-card>
    </div>
    <div xs12>
      <v-card>
        <ApexChart v-if="chartOptionsActivity" type="line" height="350" :options="chartOptionsActivity"
          :series="aggregatedActivityChart"></ApexChart>
      </v-card>
    </div>
    <div xs12>
      <v-card>
        <ApexChart v-if="chatOptions" type="line" height="350" :options="chatOptions" :series="comboChartData">
        </ApexChart>
      </v-card>
    </div>
    <div xs12>
      <v-card>
        <ApexChart v-if="chatOptions2 && aggregatedUsersChart" type="line" height="350" :options="chatOptions2"
          :series="aggregatedUsersChart"></ApexChart>
      </v-card>
    </div>
    <div xs12>
      <v-card>
        <ApexChart v-if="chartOptions3 && aggregatedAcademiesChart" type="line" height="350" :options="chartOptions3"
          :series="aggregatedAcademiesChart"></ApexChart>
      </v-card>
    </div>
    <div xs12>
      <v-card class="text-center pa-2">
        <template v-for="a in academies">
          <v-card elevation="0" outlined :key="a.country"
            style="display: inline-block; margin-right: 15px; padding: 4px">
            <img :src="assets('flags/' + a.country + '.png')" style="width: 50px" />
            <span style="font-size: 3em">{{ a.number }}%</span>
          </v-card>
        </template>
      </v-card>
    </div>
    <v-card>
      <div>
        <v-btn small block :disabled="gettingCSV" @click="getUsersCSV()" color="primary"
          v-if="isSpecialAdmin(this.$store.state.user._id)">
          <v-progress-circular size="15" v-if="gettingCSV" indeterminate color="white"></v-progress-circular>
          Users CSV
        </v-btn>
        <v-btn class="mt-1" small block :disabled="gettingCSV" @click="getUsersCSV(true)" color="primary"
          v-if="isSpecialAdmin(this.$store.state.user._id)">
          <v-progress-circular size="15" v-if="gettingCSV" indeterminate color="white"></v-progress-circular>
          Claimed Academy Managers CSV
        </v-btn>
      </div>
    </v-card>
  </v-container>
</template>

<script>
import AggregatesService from "@/services/AggregatesService";
import ApexChart from 'vue-apexcharts'

import { 
  EmojiHappyIcon,
  LocationMarkerIcon,
  BadgeCheckIcon,
  HandIcon,
  CashIcon,
  AnnotationIcon,
  VideoCameraIcon,
  MapIcon
} from '@vue-hero-icons/outline'

export default {
  data() {
    return {
      newUsersCount: null,
      gettingCSV: false,
      newAcademiesCount: null,
      checkinsCount: null,
      countriesCount: null,
      postsCount: null,
      videosCount: null,
      citiesCount: null,
      managedAcademiesCount: null,
      managedAcademiesWithPaymentsCount: null,
      claimedAcademiesCount: null,
      newUsers: [],
      academies: [],
      newAcademies: [],
      checkins: [],
      posts: [],
      yesterday: 0,
      today: 0,
      maxV: 0,
      comboChartData: null,
      aggregatedUsersChart: null,
      activityLast3MonthsData:  null,
      activityLast3MonthsOptions: null,
      aggregatedActivityChart: null,
      aggregatedAcademiesChart: null,
      chartOptionsActivity: null,
      chatOptions: null,
      chatOptions2: {
        hAxis: { title: "Day" },
      },
      chartOptions3: {
        hAxis: { title: "Day" },
        colors: ["red"],
      },
    };
  },
  components: {
    EmojiHappyIcon,
    LocationMarkerIcon,
    BadgeCheckIcon,
    HandIcon,
    CashIcon,
    AnnotationIcon,
    VideoCameraIcon,
    MapIcon,
    ApexChart
  },
  created() {
    this.activityLast3Months();
    AggregatesService.countries()
      .then((data) => {
        if (data) {
          var count = 0;
          for (const a in data.academiesByCountry) {
            if (count == 5) {
              break;
            }
            this.academies.push({
              country: a,
              number: data.academiesByCountry[a],
            });
            count++;
          }
          this.countriesCount = data.totalCountriesWithAcademies;
        }
      });
    AggregatesService.counts()
      .then((data) => {
        if (data) {
          this.citiesCount = data.totalCities;
          this.newUsersCount = data.totalUsers;
          this.today = data.totalUsersToday;
          this.yesterday = data.totalUsersYesterday;
          this.newAcademiesCount = data.totalAcademies;
          this.checkinsCount = data.totalCheckins;
          this.postsCount = data.totalPosts;
          this.videosCount = data.totalVideos;
          this.managedAcademiesWithPaymentsCount =
            data.managedAcademiesWithPaymnts;
          this.claimedAcademiesCount = data.claimedAcademies;
          this.managedAcademiesCount = data.managedAcademies;
        }
      });
    AggregatesService.internal()
      .then((data) => {
        if (data) {
          var users = data.allUsersBeginingOfInterval;
          var academies = 0;
          var days = [];
          var rawData = {
            checkins: {},
            academies: {},
            users: {},
            usersAggregated: {},
            academiesAggregated: {},
            posts: {},
            activities: {},
            activitiesRate:{},
            activityUsers: {},
          };
          for (const d of data.newUsersDaily) {
            const day = d._id.month + "-" + d._id.year;
            rawData.users[day] = d.newUsers;
            users += d.newUsers;
            rawData.usersAggregated[day] = users;
            days.push(day);
          }
          
        
          let graphDataActivity = [];
          let categories = [];
          for (const d of data.activityMonthly) {
            const month = d._id.month + "-" + d._id.year;
            graphDataActivity.push(d.activities);
            categories.push(month);
          }
          for (const d of data.checkinsDaily) {
            const day = d._id.month + "-" + d._id.year;
            rawData.checkins[day] = d.checkins;
          }
          for (const d of data.postsDaily) {
            const day = d._id.month + "-" + d._id.year;
            rawData.posts[day] = d.posts;
          }
          for (const d of data.newAcademiesDaily) {
            const day = d._id.month + "-" + d._id.year;
            rawData.academies[day] = d.newAcademies;
            academies += d.newAcademies;
            rawData.academiesAggregated[day] = academies;
          }
          let graphData = [];
          let graphDataCategories = [];
          let graphData2 = [];
          let graphData3 = [];
          graphData = [
            {
              name: 'New Users',
              data:[]
            },
            {
              name: 'New Academies',
              data:[]
            },
            {
              name: 'New Checkins',
              data:[]
            },
            {
              name: 'Posts',
              data:[]
            }
          ];
          graphData2 = [
            {
              name: 'Users',
              data:[]
            }];
          graphData3 = [
            {
              name: 'Academies',
              data:[]
            }];
          for (const d of days) {
            graphDataCategories.push(d);
            const u = rawData.users[d];
            const ua = rawData.usersAggregated[d];
            const a = rawData.academies[d];
            const aa = rawData.academiesAggregated[d];
            const c = rawData.checkins[d];
            const p = rawData.posts[d];
            graphData[0].data.push(u);
            graphData[1].data.push(a ? a : 0);
            graphData[2].data.push(c ? c : 0);
            graphData[3].data.push(p ? p : 0);

            graphData2[0].data.push(ua ? ua : 0);
            graphData3[0].data.push(aa ? aa : 0);
          }
          this.comboChartData = graphData;
          this.chatOptions = {
            chart: {
              height: 350,
              type: 'line',
              toolbar: {
                show: false
              }
            },
            colors: ['#77B6EA', this.$vuetify.theme.themes.light.primary, this.$vuetify.theme.themes.light.orange, this.$vuetify.theme.themes.light.purple],
            dataLabels: {
              enabled: false,
            },
            theme: {
              mode: this.isDarkTheme() ? 'dark' : 'light',
            },
            stroke: {
              curve: 'smooth'
            },
            title: {
              align: 'left'
            },
            markers: {
              size: 1
            },
            xaxis: {
              categories: graphDataCategories,
              title: {
                text: 'Month'
              }
            },
            yaxis: {
              title: {
                text: 'Unique Users'
              },
            },
            legend: {
              position: 'top',
              horizontalAlign: 'right',
              floating: true,
              offsetY: -25,
              offsetX: -5
            }
          };
          this.aggregatedUsersChart = graphData2;
          this.chatOptions2 = {
            chart: {
              height: 350,
              type: 'line',
              toolbar: {
                show: false
              }
            },
            colors: [this.$vuetify.theme.themes.light.primary],
            dataLabels: {
              enabled: true,
            },
            theme: {
              mode: this.isDarkTheme() ? 'dark' : 'light',
            },
            stroke: {
              curve: 'smooth'
            },
            title: {
              align: 'left'
            },
            markers: {
              size: 1
            },
            xaxis: {
              categories: graphDataCategories,
              title: {
                text: 'Month'
              }
            },
            yaxis: {
              title: {
                text: 'New Users'
              },
            },
            legend: {
              position: 'top',
              horizontalAlign: 'right',
              floating: true,
              offsetY: -25,
              offsetX: -5
            }
          };

          this.aggregatedAcademiesChart = graphData3;
          this.chartOptions3 = {
            chart: {
              height: 350,
              type: 'line',
              toolbar: {
                show: false
              }
            },
            colors: [this.$vuetify.theme.themes.light.primary],
            dataLabels: {
              enabled: true,
            },
            theme: {
              mode: this.isDarkTheme() ? 'dark' : 'light',
            },
            stroke: {
              curve: 'smooth'
            },
            title: {
              align: 'left'
            },
            markers: {
              size: 1
            },
            xaxis: {
              categories: graphDataCategories,
              title: {
                text: 'Month'
              }
            },
            yaxis: {
              title: {
                text: 'Academies'
              },
            },
            legend: {
              position: 'top',
              horizontalAlign: 'right',
              floating: true,
              offsetY: -25,
              offsetX: -5
            }
          };
          
          this.aggregatedActivityChart = [{
            name:'Unique User Activity',
            data: graphDataActivity
          }];
          this.chartOptionsActivity = {
            chart: {
              height: 350,
              type: 'line',
              toolbar: {
                show: false
              }
            },
            colors: ['#77B6EA'],
            dataLabels: {
              enabled: true,
            },
            theme: {
              mode: this.isDarkTheme() ? 'dark' : 'light',
            },
            stroke: {
              curve: 'smooth'
            },
            title: {
              align: 'left',
              text: 'Monthly Activity',
            },
            markers: {
              size: 1
            },
            xaxis: {
              categories: categories,
              title: {
                text: 'Month'
              }
            },
            yaxis: {
              title: {
                text: 'Unique Users'
              },
            },
            legend: {
              position: 'top',
              horizontalAlign: 'right',
              floating: true,
              offsetY: -25,
              offsetX: -5
            }
          };
        }
      });
  },
  methods: {
    activityLast3Months(){
      AggregatesService.activityLast3Months()
        .then((data) => {
          if (data) {
            //activityLast3MonthsData
            let series = [];
            let categories = [];
            for(const d of data.activityLast3Months){
              const days = [];
              if(d.data.length > categories.length){
                for(const day of d.data){
                  const dateObj = new Date(day.day);
                  categories.push(dateObj.getDate());
                }
              }
              for(const day of d.data){
                days.push(day.users);
              }
              const split = d.month.split('-');
              const monthObj = new Date();
              monthObj.setMonth(split[1]);
              monthObj.setMonth(monthObj.getMonth()-1);
              monthObj.setFullYear(split[0]);
              series.push({
                name: this.$d(monthObj, 'month_year', this.$i18n.locale),
                data: days
              });
            }
            this.activityLast3MonthsData = series;
            this.activityLast3MonthsOptions = {
              chart: {
                height: 350,
                type: 'line',
                toolbar: {
                  show: false
                }
              },
              colors: ['#BDBDBD', '#757575', this.$vuetify.theme.themes.light.primary],
              dataLabels: {
                enabled: false,
              },
              theme: {
                mode: this.isDarkTheme() ? 'dark' : 'light',
              },
              stroke: {
                curve: 'smooth'
              },
              title: {
                text: 'Daily activity last 3 months',
                align: 'left'
              },
              markers: {
                size: 1
              },
              xaxis: {
                categories: categories,
                title: {
                  text: 'Month'
                }
              },
              yaxis: {
                title: {
                  text: 'Unique Users'
                },
              },
              legend: {
                position: 'top',
                horizontalAlign: 'right',
                floating: true,
                offsetY: -25,
                offsetX: -5
              }
            };
          }
        });
    },
    getLATime() {
      var d = new Date();
      return d.toLocaleString("en-US", { timeZone: "America/Los_Angeles" });
    },
    getUsersCSV(claimedacademies) {
      this.gettingCSV = true;
      AggregatesService.usersCSV(claimedacademies)
        .then((data) => {
          if (data) {
            let blob = new Blob([data], { type: "text/csv" });
            let link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            link.download = "Users_" + new Date().toISOString() + "_" + ".csv";
            link.click();
            this.gettingCSV = false;
          }
        });
    },
  },
};
</script>

<style scoped>
.secondary-count {
  font-size: 4em;
}
</style>