<template>
    <v-card class="content-bg pa-2 mx-auto custom-card" max-width="300" elevation="0" outlined>
        <v-card-actions>
            <v-btn fab x-small elevation="0" color="tertiary">
                <v-icon>{{ icon }}</v-icon>
            </v-btn>
            <span class="text-overline ml-2"> {{ node.technique }}</span>
        </v-card-actions>
        <div class="text-node">
            <v-card-title class="primary--text text-subtitle-1 clickable">
                <span>{{ node.title }}</span>
            </v-card-title>
            <div v-html="node.description" class="text-caption clickable node-desc"></div>
            <v-card-text class="clickable" v-if="node.adminVideo">
                <div class="mb-2">
                    <v-icon>mdi-video-outline</v-icon>
                    {{ node.adminVideo.title }}
                </div>

                <v-img :src="userFile(node.adminVideo.thumbnail)">
                    <v-overlay absolute bottom right>
                        <v-btn large @click="showPlayVideo" icon>
                            <v-icon color="white">mdi-play-circle-outline</v-icon>
                        </v-btn>
                    </v-overlay>
                </v-img>
            </v-card-text>
            <v-card-text v-else-if="node.imageUrl">
                <v-img :src="userFile(node.imageUrl)"></v-img>
            </v-card-text>
            <v-dialog v-model="playVideoDialog" max-width="600px">
                <v-card v-if="node.adminVideo" class="pa-2 pt-10">
                    <v-card-text class="text-center">
                        <VideoDisplay v-if="showVideo" style="height: 100%; width: 100%" :video="node.adminVideo">
                        </VideoDisplay>
                    </v-card-text>
                    <v-card-actions class="mb-2">
                        <v-btn class="cancel-button ml-2" elevation="0"
                            @click="playVideoDialog = false; showVideo = false;">{{
                    $t("message.close")
                            }}</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </div>
    </v-card>
</template>
<script>
import VideoDisplay from '@/components/displays/VideoDisplay';

export default {
    name: "DemoNodeView",
    data() {
        return {
            text: 'This is component A',
            icon: 'mdi-crosshairs-gps',
            newNodeImage: null,
            playVideoDialog: null,
            showVideo: false,
        }
    },
    components: {
        VideoDisplay
    },
    props: ['remove', 'node', 'type'],
    created() {
        if (this.node && this.node.type) {
            const type = this.node.type;
            if (type == 'submission')
                this.icon = 'mdi-crosshairs-gps';
            else if (type == 'pass')
                this.icon = 'mdi-arrow-decision-outline';
            else if (type == 'mount')
                this.icon = 'mdi-arrow-collapse-down';
            else if (type == 'side-control')
                this.icon = 'mdi-selection-ellipse-arrow-inside';
            else if (type == 'sweep')
                this.icon = 'mdi-cached';
            else if (type == 'back')
                this.icon = 'mdi-arrange-send-backward';
            else if (type == 'start')
                this.icon = 'mdi-ray-start-arrow';
            else if (type == 'takedown')
                this.icon = 'mdi-arrow-down-right';
            else if (type == 'transition')
                this.icon = 'mdi-transition-masked';
            else if (type == 'guard')
                this.icon = 'mdi-shield-half-full';
            else if (type == 'standup')
                this.icon = 'mdi-arrange-send-to-back';
            else if (type == 'escape')
                this.icon = 'mdi-lock-open-check-outline';
        }
    },
    methods: {
        truncateText(string = "", num) {
            if (string.length <= num) {
                return string;
            }
            return string.slice(0, num);
        },
        showPlayVideo() {
            this.playVideoDialog = true;
            this.showVideo = true;
        }
    }
};
</script>
<style scoped>
.node-desc {
    padding: 0 10px 0 10px;
}

.text-node {
    text-align: center;
}

.text-node .text-subtitle-1 {
    font-family: "Plus Jakarta Sans", sans-serif !important;
}

.icon-block {
    background-color: #8866ff !important;
    color: #0D1117;
    border-radius: 50%;
    width: 40px;
    height: 40px;
}

.custom-card {
    border: 1px solid #8866ff60 !important;
    box-shadow: 0 0 10px #8866ff80 !important;
}
.v-card__text,
.v-card__title {
    word-break: normal;
    /* maybe !important  */
}
</style>