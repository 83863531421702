<template>
  <div v-if="$store.state.isUserLoggedIn" class="pa-0">
    <div v-if="loading">
      <v-skeleton-loader type="card, article"></v-skeleton-loader>
    </div>
    <div v-if="!loading">
      <v-sheet :rounded="isSmallerDisplay() ? '' : 'lg'">
        <BackButton v-hide-embed="$route.query.embed"></BackButton> 
        <div v-if="academy.altImage" class="text-center">
          <v-responsive class="academy-cover"
            :style="academyCoverRadious+academyCoverGradient+',url(\''+userFile(academy.altImage)+'\');'">
            <v-badge color="none" icon v-if="academy.managers && academy.managers.length > 0" overlap right large>
              <template v-slot:badge>
                <BadgeCheckIcon :color="themeColor('primary')" class="hero-icon"></BadgeCheckIcon>
              </template>
              <PageAvatar :src="academy.image" size="150px" object="academy" :country="academy.city.country" />
            </v-badge>
            <PageAvatar v-else :src="academy.image" size="150px" object="academy" :country="academy.city.country" />
          </v-responsive>
        </div>
        <div v-else class="text-center">
          <v-card-text>
            <v-badge color="none" icon v-if="academy.managers && academy.managers.length > 0" overlap right large>
              <template v-slot:badge>
                <BadgeCheckIcon :color="themeColor('primary')" class="hero-icon"></BadgeCheckIcon>
              </template>
              <PageAvatar :src="academy.image" size="150px" object="academy" :country="academy.country" />
            </v-badge>
            <PageAvatar v-else :src="academy.image" size="150px" object="academy" :country="academy.country" />
          </v-card-text>
        </div>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-menu offset-y v-if="isManager || isAppAdmin($store.state.user._id)">
            <template v-slot:activator="{ on }">
              <v-btn class="elevation-0" v-on="on" icon small slot="activator">
                <DotsVerticalIcon class="hero-button"></DotsVerticalIcon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item v-if="isManager" @click.stop="createEvent()">
                <v-list-item-title>{{ $t('message.create_event') }}</v-list-item-title>
              </v-list-item>
              <v-list-item @click.stop="goToEdit()" v-if="isManager || isAppAdmin($store.state.user._id)">
                <v-list-item-title>{{ $t('message.edit') }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-card-actions>
        <v-card-title>
          {{ academy.name }}
        </v-card-title>
        <div>
          <v-list-item v-if="academy.identifier">
            <v-list-item-action>
              <FingerPrintIcon class="hero-icon"></FingerPrintIcon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>
                <span v-if="academy.identifier">{{academy.identifier}}</span>
                <ClipboardCopyIcon class="hero-icon clickable ml-1" size="15" v-if="academy.identifier"
                  @click="copyPublicUrl()"></ClipboardCopyIcon>
              </v-list-item-title>
              <v-list-item-subtitle>
                {{$t("message.identifier")}}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item link @click="goToDirections(academy.address)">
            <v-list-item-action>
              <LocationMarkerIcon class="hero-icon"></LocationMarkerIcon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>
                {{academy.address}}
              </v-list-item-title>
              <v-list-item-subtitle>
                {{ $t("message.address") }}
                <v-icon size="20" class="hero-icon clickable">mdi-directions</v-icon>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-action>
              <IdentificationIcon class="hero-icon"></IdentificationIcon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>
                {{academy.contact}}
              </v-list-item-title>
              <v-list-item-subtitle>
                {{ $t("message.contact") }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item v-if="academy.phone">
            <v-list-item-action>
              <PhoneIcon class="hero-icon"></PhoneIcon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>
                {{academy.phone}}
              </v-list-item-title>
              <v-list-item-subtitle>
                {{ $t("message.phone") }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item v-if="academy.website" @click="goToLink(academy.website)">
            <v-list-item-action>
              <ExternalLinkIcon class="hero-icon"></ExternalLinkIcon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>
                {{academy.website}}
              </v-list-item-title>
              <v-list-item-subtitle>
                {{ $t("message.website") }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item v-if="academy.affiliation">
            <v-list-item-action>
              <OfficeBuildingIcon class="hero-icon"></OfficeBuildingIcon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>
                {{academy.affiliation}}
              </v-list-item-title>
              <v-list-item-subtitle>
                {{ $t("message.affiliation") }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item v-if="academy.localAffiliation">
            <v-list-item-action>
              <LibraryIcon class="hero-icon"></LibraryIcon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>
                {{academy.localAffiliation}}
              </v-list-item-title>
              <v-list-item-subtitle>
                {{ $t("message.local_affiliation") }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item v-if="academy && academy.members && academy.members.length > 0">
            <v-list-item-action>
              <UserGroupIcon class="hero-icon"></UserGroupIcon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>
                <a @click="membersDialog = true;getMembers()">{{ academy.members ? academy.members.length : 0}} {{ $t("message.members") }}</a>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-action>
              <HandIcon class="hero-icon"></HandIcon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>
                {{ $t("message.managers") }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <div v-if="managers" class="pa-2">
            <template v-for="m in managers">
              <v-chip small class="mr-1" @click:close="showRemoveManagerConfirm(m)"
                :close="managers.length > 1 && isManager" :key="'manager' + m._id">{{ m.fName }} {{ m.lName }}</v-chip>
            </template>
          </div>
          <div v-else class="pl-2 ml-2 text-subtitle-1">
            {{ $t("message.no_managers") }}
            <a @click="claimDialog = true">{{$t("message.claim_academy")}}</a>
          </div>
        </div>
        <v-card-actions>
          <AcademyLikes :academy="academy" />
          <v-spacer></v-spacer>
          <AcademyStars :academy-id="academy._id" :show-reviews="true" :rating="academy.rating ? academy.rating : 0"
            :count="reviewsCount" />
        </v-card-actions>
      </v-sheet>
    <v-sheet :rounded="isSmallerDisplay() ? '' : 'lg'" class="mt-2" v-if="canSign">
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn small color="primary" @click="triggerSignWaiver()">
          {{ $t("message.sign_waiver") }}
        </v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-sheet>
    <v-sheet :rounded="isSmallerDisplay() ? '' : 'lg'" class="mt-2"
      v-if="academy.description && academy.description != ''">
      <v-expansion-panels flat>
        <v-expansion-panel :class="$store.state.theme == 'light' ? 'border-light' : 'border-dark'">
          <v-expansion-panel-header outlined>
            {{ $t("message.description") }}
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-card-text class="page-description" v-html="academy.description"></v-card-text>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-sheet>
    <v-sheet :rounded="isSmallerDisplay() ? '' : 'lg'" class="mt-2" v-if="hasSchedule(academy.schedule)">
      <v-expansion-panels flat>
        <v-expansion-panel :class="$store.state.theme == 'light' ? 'border-light' : 'border-dark'">
          <v-expansion-panel-header outlined>
            <div>
              {{ $t("message.schedule") }}
              <ExclamationIcon size="20" v-if="scheduleNotes" class="hero-icon" :color="themeColor('error')">
              </ExclamationIcon>
            </div>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-card-text v-if="scheduleNotes" class="text-center">
              <ExclamationIcon size="15" v-if="scheduleNotes" class="hero-icon"></ExclamationIcon>
              {{scheduleNotes.notes}}
            </v-card-text>
            <v-card-text>
              <schedule-display :professors="professors" :schedule="academy.schedule" />
            </v-card-text>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-sheet>
    <v-sheet :rounded="isSmallerDisplay() ? '' : 'lg'" class="mt-2" v-if="hasSchedule(academy.schedule)">
      <v-expansion-panels flat>
        <v-expansion-panel :class="$store.state.theme == 'light' ? 'border-light' : 'border-dark'">
          <v-expansion-panel-header outlined>
            <div>
              {{ $t("message.classes") }}
            </div>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-list>
              <template v-for="(e, index) in calendar">
                <v-list-item :key="'event_' + index" @click="goToAcademyClass(e)">
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ e.title }}
                      <span v-if="e.maxCapacity > 0" class="supporting-text" style="margin-right:3px">
                        {{e.approved ? e.approved.length : 0}}\{{e.maxCapacity}}
                      </span>
                    </v-list-item-title>
                    <v-list-item-title v-if="e.professorUser" class="supporting-text">
                      {{ $t("message.professor") + ": " +e.professorUser.displayName }}
                    </v-list-item-title>
                    <v-list-item-title style="color: gray;">
                      <span>
                        {{
                        $d(new Date(e.date), "long", $i18n.locale)
                        }}
                      </span>
                    </v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-action v-if="e.status || e.canJoin || e.canWaitlist">
                    <v-btn @click.stop="joinAcademyClass(e._id)" color="primary" elevation="0" small fab
                      v-if="!e.status && e.canJoin">
                      <LoginIcon class="hero-button"></LoginIcon>
                    </v-btn>
                    <v-btn @click.stop="joinAcademyClass(e._id)" color="secondary" elevation="0" small fab
                      v-else-if="!e.status && e.canWaitlist">
                      <LoginIcon class="hero-button"></LoginIcon>
                    </v-btn>
                    <v-btn fab elevation="0" :color="e.canWaitlist ? 'error' : ''" small
                      v-else-if="e.status == 'pending'">
                      <ClockIcon class="hero-button"></ClockIcon>
                    </v-btn>
                    <v-btn elevation="0" fab small medium v-else-if="e.status == 'approved'">
                      <CheckCircleIcon class="hero-button"></CheckCircleIcon>
                    </v-btn>
                  </v-list-item-action>
                </v-list-item>
              </template>
            </v-list>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-sheet>
    <div class="mt-2">
      <v-card style="height: 390px;">
        <GmapMap v-if="position" style="height: 390px;" :center="position" :zoom="13" map-type-id="roadmap">
          <GmapMarker v-if="position" :position="position" :icon="getIcon()" />
        </GmapMap>
      </v-card>
    </div>
  </div>
  <v-dialog v-model="claimDialog" max-width="90%" light>
    <v-card outlined>
      <v-card-title>
        <h2>{{ $t("message.claim_academy_management") }}</h2>
      </v-card-title>
      <v-card-text>
        <h3>{{ $t("message.claim_academy_message") }}</h3>
        <v-text-field v-model="claimContact" label="Email"></v-text-field>
      </v-card-text>
      <v-card-actions>
        <v-btn color="primary" @click.stop="claimAcademy()">
          {{
          $t("message.send_info")
          }}
        </v-btn>
        <v-btn @click.stop="claimDialog = false">
          {{
          $t("message.close")
          }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <v-dialog v-model="membersDialog" fullscreen hide-overlay transition="dialog-bottom-transition">
    <v-card>
      <v-toolbar dark color="secondary">
        <v-spacer></v-spacer>
        <v-btn icon dark @click="membersDialog = false">
          <XCircleIcon class="hero-button"></XCircleIcon>
        </v-btn>
      </v-toolbar>
      <v-card-text class="pa-0">
        <v-skeleton-loader v-if="loadingMembers"
          type="list-item-avatar-two-line, list-item-avatar-two-line, list-item-avatar-two-line">
        </v-skeleton-loader>
        <div v-else>
          <div v-if="!members || members.length == 0">
            {{$t("message.no_results")}}
          </div>
          <div v-else>
            <template v-for="(u, index) in members">
              <v-list-item class="pl-1 pr-1" two-line v-if="!u.hidden" :key="u._id" @click="goToUser(u._id)" ripple>
                <v-list-item-avatar :tile="true">
                  <UserAvatar :user="u" size="28" :small-badge="true" :has-progress="false">
                  </UserAvatar>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title>
                    <UserName :user="u" :link="false" :add-hover="false" :hide-flag="true" :avatar="false">
                    </UserName>
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    <BeltDisplay size="15" class="mr-1" :hide-icon="false" :show-text="u.academyName ? false : true"
                      :belt="u.belt"></BeltDisplay>
                  </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action v-if="u.canLink">
                  <LinkButton v-if="$store.state.user._id != u._id" :linked="false" :userId="u._id" :small="true">
                  </LinkButton>
                </v-list-item-action>
                <v-list-item-action v-if="!isManaged && !userIsManager(u._id) && isManager">
                  <v-btn icon small>
                    <UserAddIcon class="hero-button" @click.stop="showAddManagerConfirmDialog(u)" light>
                    </UserAddIcon>
                  </v-btn>
                </v-list-item-action>
                <v-list-item-action v-else>
                  <CountryFlag size="35" :avatar="true" :country-code="u.nationality"></CountryFlag>
                </v-list-item-action>
              </v-list-item>
              <v-divider :key="index+'_member'"></v-divider>
            </template>
          </div>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
  <v-dialog v-model="addManagerConfirm" max-width="500px">
    <v-card outlined>
      <v-card-title class="text-subtitle-1">
        {{ $t("message.add_manager_message") }}
      </v-card-title>
      <v-card-actions>
        <v-btn small color="primary" @click.stop="addManager()">
          {{
          $t("message.add_manager")
          }}
        </v-btn>
        <v-btn small color="error" @click.stop="cancelAddManager()">
          {{
          $t("message.close")
          }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <v-dialog v-model="removeManagerConfirm" max-width="500px">
    <v-card outlined>
      <v-card-title class="text-subtitle-1">
        {{ $t("message.remove_manager_message") }}
      </v-card-title>
      <v-card-actions>
        <v-btn small color="primary" @click.stop="removeManager()">
          {{
          $t("message.remove_manager")
          }}
        </v-btn>
        <v-btn small color="error" @click.stop="cancelRemoveManager()">
          {{
          $t("message.close")
          }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  </div>
</template>

<script>
import AcademyCalendarService from "@/services/AcademyCalendarService";
import AcademyService from "@/services/AcademyService";
import NotificationService from "@/services/NotificationService";
import PageAvatar from "@/components/displays/CountryObjectAvatar.vue";
import AcademyLikes from "@/components/displays/AcademyLikesDisplay.vue";
import ScheduleDisplay from "@/components/displays/ScheduleDisplay.vue";
import AcademyStars from "@/components/displays/AcademyStarsDisplay.vue";
import ReviewService from "@/services/ReviewService";
import UserAvatar from "@/components/displays/UserAvatarDisplay";
import BeltDisplay from "@/components/displays/BeltDisplay";
import UserName from "@/components/displays/UserNameDisplay.vue";
import CountryFlag from "@/components/displays/CountryFlagDisplay.vue";
import LinkButton from "@/components/displays/LinkUserButtonDisplay.vue";
import BackButton from "@/components/displays/BackButtonDisplay.vue";

import { 
  LocationMarkerIcon,
  UserGroupIcon,
  PhoneIcon,
  OfficeBuildingIcon,
  FingerPrintIcon,
  DotsVerticalIcon,
  HandIcon,
  ExternalLinkIcon,
  IdentificationIcon,
  XCircleIcon,
  UserAddIcon,
  ExclamationIcon,
  LibraryIcon,
  ClipboardCopyIcon,
} from '@vue-hero-icons/outline'

import { 
  BadgeCheckIcon,
} from '@vue-hero-icons/solid'

export default {
  components: {
    AcademyLikes,
    BackButton,
    UserName,
    PageAvatar,
    BeltDisplay,
    UserAvatar,
    ScheduleDisplay,
    AcademyStars,
    BadgeCheckIcon,
    LocationMarkerIcon,
    UserGroupIcon,
    PhoneIcon,
    OfficeBuildingIcon,
    FingerPrintIcon,
    DotsVerticalIcon,
    HandIcon,
    ExternalLinkIcon,
    IdentificationIcon,
    XCircleIcon,
    UserAddIcon,
    LinkButton,
    ExclamationIcon,
    LibraryIcon,
    CountryFlag,
    ClipboardCopyIcon,
  },
  data() {
    return {
      academyId: this.$route.params.academyId,
      notificationId: this.$route.params.notificationId,
      academy: null,
      academyCreated: this.$route.query.academyCreated,
      pointer: 0,
      scheduleNotes: null,
      membersDialog: false,
      members: null,
      managers: null,
      claimContact: null,
      loading: true,
      futureManager: null,
      loadingMembers: true,
      isManaged: false,
      viewMore: false,
      addManagerConfirm: false,
      removeManagerConfirm: false,
      position: null,
      professors: null,
      claimDialog: false,
      canSign: false,
      managedAcademyId: null,
      academyImage: null,
      isManager: false,
      isMember: false,
      videoFound: false,
      videoContents: null,
      videoId: null,
      calendar: null,
      successFunction: function (uploadedFile, vm) {
        if (vm && uploadedFile) vm.academyImage = uploadedFile;
      },
      clearPost: false,
      reviewsCount: 0,
      rating: null,
      academyCoverGradient: this.isDarkTheme() ? 'background-image:linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0), rgba(255, 255, 255, 0), rgba(30, 30, 30, 100))' : 
        'background-image:linear-gradient(to bottom, rgba(30, 38, 48, 0), rgba(30, 38, 48, 0), rgba(30, 38, 48, 0), rgba(255, 255, 255, 100))',
      academyCoverRadious: !this.isSmallerDisplay() ? 'border-radius:5px 5px 0 0px;' : ''
    };
  },
  beforeRouteUpdate(to, from, next) {
    this.academyId = to.params.academyId;
    this.loading = true;
    this.academy = null;
    this.isManager = false;
    this.isMember = false;
    this.initializeProfile();
    next();
  },
  async created() {
    if (this.academyCreated)
      this.showAlert(
        "info",
        this.$t("message.academy_created"),
        this.$t("message.academy_created_more"),
        15000
      );
    if (this.notificationId) this.readNotification();
    if (this.academyId != "add") this.initializeProfile();
  },
  methods: {
    getMembers() {
      this.loadingMembers = true;
      AcademyService.members(this.academyId)
        .then((data) => {
          if (data) {
            this.members = data;
            for (let u of this.members) {
              if (u.linkedBy) {
                let i = u.linkedBy.indexOf(this.$store.state.user._id);
                if (i >= 0) u.alreadyLinked = true;
                else u.alreadyLinked = false;
              }
            }
            this.loadingMembers = false;
          }
        });
    },
    triggerSignWaiver(){
      this.navigateTo({
        name: "viewacademyvisitorwaiver",
        params: { academyId: this.academyId},
      });
    },
    copyPublicUrl() {
      let urlToCopy = 'https://bjjlink.com/pub/' + this.academy.identifier;
      this.copyToClipboard(urlToCopy);
    },
    doNothing() {},
    joinAcademyClass(id) {
      AcademyCalendarService.joinAcademyClass(id)
        .then((data) => {
          if (data) {
            this.getCalendar();
            this.showAlert("success", this.$t("message.success"));
          }
        })
        .catch((err) => {
          if(err.response && err.response.status === 405)
            this.showAlert("error", this.$t("message.need_membership_for_class"));
          else
            this.showAlert("error", this.$t("message.oops_not_working"));
        });
    },
    goToAcademyClass(e) {
      //Are you a member?
      if(this.isMember || e.allowNonMembers){
        this.navigateTo({
          name: "viewacademyclass",
          params: { academyClassId: e._id },
        });
      }
    },
    goToEdit() {
      this.navigateTo({ name: "manageacademy", params: { academyId: this.academyId } });
    },
    readNotification() {
      NotificationService.read(this.notificationId);
    },
    createEvent() {
      this.navigateTo({
        name: "createeventacademy",
        params: { eventId: "new_academy_event", academyId: this.academyId },
      });
    },
    getIcon() {
      return this.assets('logo_map.png');
    },
    goToUser(id) {
      this.navigateTo({ name: "viewprofile", params: { userId: id } });
    },
    showRemoveManagerConfirm(m) {
      this.futureManager = m;
      this.removeManagerConfirm = true;
    },
    async removeManager() {
      var updatedManagers = [];
      var i;
      for (i = 0; i < this.managers.length; i++) {
        const m = this.managers[i];
        if (m._id == this.futureManager._id) {
          break;
        }
      }
      this.managers.splice(i, 1);
      for (const m of this.managers) updatedManagers.push(m._id);
      const data = [
        {
          propName: "managers",
          value: updatedManagers,
        },
      ];
      const updated = await AcademyService.updateAcademyManagers(
        this.academyId,
        data
      );
      if (updated) {
        //Do nothing
      } else {
        this.showAlert("error", this.$t("message.oops_not_working"));
      }
      this.removeManagerConfirm = false;
      this.futureManager = null;
    },
    async addManager() {
      var updatedManagers = [];
      if (this.managers) {
        for (const m of this.managers) updatedManagers.push(m._id);
      }
      updatedManagers.push(this.futureManager._id);
      const data = [
        {
          propName: "managers",
          value: updatedManagers,
        },
      ];
      const updated = await AcademyService.updateAcademyManagers(
        this.academyId,
        data
      );
      if (updated) {
        this.managers.push(this.futureManager);
        //Push notification to new user
        const data = {
          contents:
            this.$store.state.user.fName +
            " " +
            this.$store.state.user.lName +
            " has added you as a manager for " +
            this.academy.name,
          type: "new_manager",
          title: "You are a manager!",
          recipient: this.futureManager._id,
          emoji: "🗣",
        };
        NotificationService.push(this.$store.state.user._id, data);
      } else {
        this.showAlert("error", this.$t("message.oops_not_working"));
      }
      this.addManagerConfirm = false;
      this.futureManager = null;
    },
    showAddManagerConfirmDialog(m) {
      this.addManagerConfirm = true;
      this.futureManager = m;
    },
    cancelAddManager() {
      this.addManagerConfirm = false;
      this.futureManager = null;
    },
    cancelRemoveManager() {
      this.removeManagerConfirm = false;
    },
    async claimAcademy() {
      const postData = {
        academy: this.academy.name,
        user: this.$store.state.user.fName + " " + this.$store.state.user.lName,
        contact: this.claimContact,
      };
      const sent = await AcademyService.claim(this.academyId, postData);
      if (sent) {
        this.showAlert(
          "success",
          this.$t("message.claimed_academy_sent")
        );
      } else {
        this.showAlert("error", this.$t("message.oops_not_working"));
      }
      this.claimDialog = false;
      this.claimContact = null;
    },
    getMapCenter() {},
    userIsManager(id) {
      if (this.academy && this.academy.managers) {
        for (const m of this.academy.managers) {
          if (m._id == id) return true;
        }
      }
      return false;
    },
    getCalendar() {
      AcademyService.calendar(this.academyId)
        .then((data) => {
          if (data.events && data.events.length > 0) {
            this.isMember = data.isMember;
            var tmp = [];
            for (var c of data.events) {
              const userId = this.$store.state.user._id;
              if (c.approved && c.approved.indexOf(userId) >= 0) {
                c["status"] = "approved";
              } else if (c.pending && c.pending.indexOf(userId) >= 0) {
                c["status"] = "pending";
                //Check max capacity to see if this is a waiting list person
                if (c.maxCapacity) {
                  const maxCapacity = c.maxCapacity;
                  const totalApproved = c.approved ? c.approved.length : 0;
                  if (totalApproved >= maxCapacity) c["canWaitlist"] = true;
                }
              } else {
                if (c.maxCapacity) {
                  const maxCapacity = c.maxCapacity;
                  const totalApproved = c.approved ? c.approved.length : 0;
                  if (totalApproved < maxCapacity){
                    //We have not reached max capacity
                    if(this.isMember || c.allowNonMembers)
                      c["canJoin"] = true;
                  }else if (c.allowWaitlist){
                    if(this.isMember || c.allowNonMembers)
                      c["canWaitlist"] = true;
                  } 
                }
              }
              tmp.push(c);
            }
            this.calendar = tmp;
          }
        });
    },
    initializeProfile() {
      AcademyService.academy(this.academyId, true)
        .then((data) => {
          if (data) {
            this.academy = data.academy;
            this.professors = data.professors;
            this.isManaged = data.isManaged;
            this.scheduleNotes = data.scheduleNotes;
            AcademyService.publicId(this.academyId).then((data) => {
              if (data) {
                this.academy.identifier = data.identifier;
              }
            });
            this.loading = false;
            if (this.academy.managers && this.academy.managers.length > 0)
              this.managers = this.academy.managers;
            this.position = {
              lat: this.academy.latitude,
              lng: this.academy.longitude,
            };
            if (this.academy.image && this.academy.image != "")
              this.academyImage = this.academy.image;
            this.isManager = this.userIsManager(this.$store.state.user._id);
          }
        })
        .catch((err) => {
          if(err)
            this.showAlert("error", this.$t("message.oops_not_working"));
        });
      AcademyService.canSignWaiver(this.academyId).then((data) => {
        if (data) {
          this.canSign = data.canSign;
          this.managedAcademyId = data.managedAcademy
        }
      });
      ReviewService.getRating(this.academyId)
        .then((data) => {
          if (data) {
            if (data.rating) this.rating = data.rating;
            else this.rating = 0;
            if (data.totalReviews) this.reviewsCount = data.totalReviews;
            else this.reviewsCount = 0;
          }
        });
      this.getCalendar();
    },
  },
  mounted() {},
  computed: {},
  watch: {},
};
</script>

<style scoped>
.academy-cover{
  padding-top:15px;
  height:200px;
  background-position: center;
  background-size: cover;
}
</style>
